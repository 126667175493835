<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { mapGetters, mapActions } from 'vuex';

import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    
    return {
      errors:[],
      title: '',
      item: {
        name: '',
        disabled: false
      },
      breadcrumbs: [
        {
          text: 'Accounts',
          href: 'accounts',
          to: { name: 'accounts' }
        }
      ]
    };
  },
  props:{
    id: {
      type: String,
      required: true,
    },
  },
  created() {
    if(!this.isNew) {
      this.loadById(this.id).then((response)=>{
        this.item = JSON.parse(JSON.stringify(response));
      });
    }
    this.title = this.isNew ? 'New account' : 'Edit account';
  },
  watch: {
   
  },
  computed: {
    isNew(){
      return  this.id.toLowerCase().trim() === 'new';
    },
    ...mapGetters('auth', {
        permissios: 'accountPermissions'
    }),
    ...mapGetters('accounts', {
        loading: 'loading',
        processing: 'processing',
        items: 'items'
    })
  },
  methods: {
     ...mapActions('accounts', {
        loadById: 'loadById',
        delete: 'delete',
        create: 'create',
        update: 'update'
    }),
    save(){
      let q;
      if(this.isNew) {
        q = this.create(this.item);
      } else {
        q = this.update( {id: this.id, input: this.item} );
      }
      q.then(() => {
        this.$router.push({name: 'accounts'});
      }).catch(errors => {
        this.errors = errors;
      });
    },
    confirmDelete() {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
         this.handleDelete();
        }
      });
    },
    handleDelete(){
     this.delete(this.id).then(() => {
        Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
        this.$router.push({name: 'accounts'});
      })
      .catch(errors => {
        this.errors = errors;
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="loading || processing"/>
    
    <div class="card p-0 cardc8">
      <div class="card-content m-3">

        <div class="alert mt-3 mb-4 alert-dismissible alert-danger" v-if="errors.length > 0">
          <button type="button" aria-label="Close" class="close" @click="errors=[]">×</button>
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </div>
       

        <div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-4 col-lg-3 col-xl-2 ">Name <small class="text-muted">[required]</small></div>
              <div class="col-md-8 col-lg-9 col-xl-10">
                <input type="text" v-model="item.name" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-4 col-lg-3 col-xl-2 ">Disabled</div>
              <div class="col-md-8 col-lg-9 col-xl-10">
                <b-form-checkbox v-model="item.disabled" switch class="" size="lg">
                </b-form-checkbox>
              </div>
            </div>
          </div>
          
          <div class="form-group">
            <div class="row">
              <div class="col-md-12">
                <button class="btn btn-outline-danger" v-if="!isNew" @click="confirmDelete">
                   <i class="fa fa-trash mr-2"/> Delete
                </button>

                <button class="btn btn-primary float-right"  @click="save" :disabled="processing || loading">
                    <i class="fa fa-save mr-2" v-if="!processing"/>  
                    <i class="fa fa-spin fa-spinner mr-2" v-if="processing"/>  
                    Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>